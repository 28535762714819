"use client";

import { createContext, useContext, useState, useEffect, useCallback, useMemo } from "react";
import useQuery from "@src/hooks/use-query";
import { useCurrentUser } from "@src/hooks/use-current-user";
import { ROUTES } from "@src/constants/routes";
import { menuConfig } from "./config";
import { useSelector } from "react-redux";
import { selectConfig, selectCurrencyRates, selectMenuConfigList } from "@src/store/selectors/config";
import { selectUser } from "@src/store/selectors/user";
type ConfigType = {
  footer_code: string;
  header_code: string;
  meta: string;
  customer_keywords: string[];
  total_cart_items: number;
  trending_keywords: string[];
  payment_methods: Array<any>;
  flash_sale: {
    id: number;
    from: string;
    to: string;
    isActive: boolean;
    isNotStarted: boolean;
    isGoingOn: boolean;
  };
  collections: Array<{
    id: number;
    name: string;
    slug: string;
  }>;
  categories: Array<{
    id: number;
    name: string;
    slug: string;
  }>;
  on_sale: boolean;
  currencyRates: [];
  branches: Array<{
    id: number;
    name: string;
    address: string;
    phone: string;
    email: string;
    latitude: number;
    longitude: number;
  }>;
};
const defaultCtx = {
  config: null as ConfigType | null,
  menuConfigList: [],
  onSaleColor: "" as string | null,
  user: {},
  refetch: () => {},
  locale: "vi",
  setLocale: lang => {},
  messages: {} as any,
  currencyRates: [] as string[]
};
const LayoutContext = createContext(defaultCtx);
interface Props {
  children: any;
  rootConfig?: ConfigType;
}
export const LayoutProvider = ({
  children,
  rootConfig
}: Props) => {
  const config = useSelector(selectConfig);
  const currencyRates = useSelector(selectCurrencyRates);
  const menuConfigList = useSelector(selectMenuConfigList);
  const user = useSelector(selectUser);
  const [locale, setLocale] = useState("en");
  const [messages, setMessages] = useState({});
  const handleRefetch = useCallback(() => {}, []);
  const onSaleColor = useMemo(() => {
    return config?.on_sale ? "#D8042C" : null;
  }, [config?.on_sale]);
  return <LayoutContext.Provider value={{
    config,
    menuConfigList,
    user,
    refetch: handleRefetch,
    currencyRates,
    onSaleColor,
    locale,
    setLocale,
    messages
  }} data-sentry-element="unknown" data-sentry-component="LayoutProvider" data-sentry-source-file="provider.tsx">
      {children}
    </LayoutContext.Provider>;
};
export function useLayoutContext() {
  return useContext(LayoutContext);
}