"use client";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { NextImage, CancelButton, NextLink } from "@src/components";
import Icons from "../Icons";
import { useRouter } from "next/navigation";
import DangerouslySetInnerHTML from "@src/components/DangerouslySetInnerHTML";
import LikeIcon from "./LikeIcon";
import Link from "next/link";
import { useLayoutContext } from "@src/layouts/provider";
import { useTranslation } from "react-i18next";
interface Props {
  id: number;
  title: string;
  content: string;
  image: string;
  publishDate: string;
  slug: string;
  redirectUrl: string;
  wishlistsCount: number;
}
function BlogCard({
  id,
  title,
  content,
  image,
  publishDate,
  slug,
  redirectUrl,
  wishlistsCount
}: Props) {
  const router = useRouter();
  const {
    t
  } = useTranslation();
  const handleRedirectDetail = () => {
    router.push(redirectUrl);
  };
  return <Stack direction={"column"} sx={{
    padding: "16px",
    borderRadius: "16px",
    "&:hover": {
      boxShadow: "0 10px 40px -4px rgba(0, 0, 0, .16)"
    }
  }} data-sentry-element="Stack" data-sentry-component="BlogCard" data-sentry-source-file="index.tsx">
      <Link href={redirectUrl} data-sentry-element="Link" data-sentry-source-file="index.tsx">
        <NextImage className="zoom-in flashing" role="button" src={image || "/assets/images/no-image-placeholder.png"} sx={{
        cursor: "pointer",
        borderRadius: "16px",
        aspectRatio: "16/9",
        "& img": {
          position: "relative !important",
          borderRadius: "16px",
          height: "328px",
          objectFit: "cover",
          aspectRatio: "16/9"
        },
        width: "100%"
      }} data-sentry-element="NextImage" data-sentry-source-file="index.tsx" />
      </Link>
      <NextLink sx={{
      fontSize: 24,
      lineHeight: "32px",
      fontWeight: 600,
      display: "block",
      marginTop: "24px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    }} href={redirectUrl} data-sentry-element="NextLink" data-sentry-source-file="index.tsx">
        <DangerouslySetInnerHTML className="truncate-1" html={title} data-sentry-element="DangerouslySetInnerHTML" data-sentry-source-file="index.tsx" />
      </NextLink>
      <Box sx={{
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: "3",
      WebkitBoxOrient: "vertical",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "20px",
      marginBottom: "24px",
      height: "60px"
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <DangerouslySetInnerHTML className="truncate-3" html={content} data-sentry-element="DangerouslySetInnerHTML" data-sentry-source-file="index.tsx" />
      </Box>
      <Stack sx={{
      height: "30px"
    }} justifyContent={"space-between"} alignItems={"center"} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
        <Stack gap={"8px"} alignItems={"center"} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
          <Icons name="clock" data-sentry-element="Icons" data-sentry-source-file="index.tsx" />
          <Typography data-sentry-element="Typography" data-sentry-source-file="index.tsx">{publishDate}</Typography>
          <Box sx={{
          width: "1.5px",
          height: "12px",
          backgroundColor: "#7D7D7D",
          margin: "0px 8px"
        }} data-sentry-element="Box" data-sentry-source-file="index.tsx" />
          <LikeIcon blogId={id} slug={slug} data-sentry-element="LikeIcon" data-sentry-source-file="index.tsx" />
          <Typography data-sentry-element="Typography" data-sentry-source-file="index.tsx">{wishlistsCount || 0}</Typography>
        </Stack>
        <CancelButton type="button" label={t('see_more')} sx={{
        borderRadius: "816px !important",
        color: "#434343 !important",
        height: "30px !important",
        minHeight: "30px !important"
      }} onClick={handleRedirectDetail} data-sentry-element="CancelButton" data-sentry-source-file="index.tsx" />
      </Stack>
    </Stack>;
}
export default BlogCard;