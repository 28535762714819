import { Modal, NextImage } from "@src/components";
import { useTranslation } from "react-i18next";
export default function SizeModal(props) {
  const {
    imgUrl,
    open,
    onClose
  } = props;
  const {
    t
  } = useTranslation();
  return <Modal title={t("size_selection_guide")} maxWidth="lg" open={open} onClose={onClose} sx={{
    "& .MuiDialog-paper": {
      margin: "0px 16px",
      overflow: "auto",
      width: "fit-content",
      minWidth: {
        xs: "calc(100% - 32px)",
        md: "auto"
      }
    }
  }} data-sentry-element="Modal" data-sentry-component="SizeModal" data-sentry-source-file="size-modal.tsx">
      <NextImage alt="product size" src={imgUrl} sx={{
      width: "100%",
      height: "auto",
      "& img": {
        position: "relative !important",
        maxHeight: "80vh"
      }
    }} data-sentry-element="NextImage" data-sentry-source-file="size-modal.tsx" />
    </Modal>;
}