"use client";

import { useLayoutContext } from "@src/layouts/provider";
import { formatCurrency } from "@src/utils/currency";
import { useTranslation } from "react-i18next";
interface Props {
  price;
}
const FormattedPrice = ({
  price
}: Props) => {
  const {
    i18n
  } = useTranslation();
  const {
    currencyRates
  } = useLayoutContext();
  return <>{formatCurrency(price ? price : 0, currencyRates, i18n.language)}</>;
};
export default FormattedPrice;