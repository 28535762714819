import { getCurrencyCodeFromCookie } from "@src/api/lib/currencyUtils";

interface CurrencyConfig {
  symbol: string;
  decimals: number;
  symbolPosition?: 'prefix' | 'suffix';
  spaceBetween?: boolean;
}

const currencyConfig: Record<string, CurrencyConfig> = {
  VND: {
    symbol: '₫',
    decimals: 0,
    symbolPosition: 'suffix'
  },
  USD: {
    symbol: '$',
    decimals: 2,
    symbolPosition: 'prefix'
  },
  EUR: {
    symbol: '€',
    decimals: 2,
    symbolPosition: 'suffix',
    spaceBetween: true
  },
  GBP: {
    symbol: '£',
    decimals: 2,
    symbolPosition: 'prefix'
  },
  JPY: {
    symbol: '¥',
    decimals: 0,
    symbolPosition: 'prefix'
  },
  CNY: {
    symbol: '¥',
    decimals: 2,
    symbolPosition: 'prefix'
  },
  AUD: {
    symbol: 'A$',
    decimals: 2,
    symbolPosition: 'prefix'
  },
  CAD: {
    symbol: 'C$',
    decimals: 2,
    symbolPosition: 'prefix'
  },
  CHF: {
    symbol: 'CHF',
    decimals: 2,
    symbolPosition: 'suffix',
    spaceBetween: true
  },
  HKD: {
    symbol: 'HK$',
    decimals: 2,
    symbolPosition: 'prefix'
  },
  SGD: {
    symbol: 'S$',
    decimals: 2,
    symbolPosition: 'prefix'
  },
  INR: {
    symbol: '₹',
    decimals: 2,
    symbolPosition: 'prefix'
  },
  KRW: {
    symbol: '₩',
    decimals: 0,
    symbolPosition: 'prefix'
  },
  BRL: {
    symbol: 'R$',
    decimals: 2,
    symbolPosition: 'prefix'
  },
  MXN: {
    symbol: 'MX$',
    decimals: 2,
    symbolPosition: 'prefix'
  },
  ZAR: {
    symbol: 'R',
    decimals: 2,
    symbolPosition: 'prefix'
  }
};

export const formatCurrency = (
  amount: number,
  currencyRates: string[],
  locale
) => {
  const currency = getCurrencyCodeFromCookie()
  const rate = currencyRates?.[currency] ?? 1;
  const convertedAmount = amount * rate;
  const config = currencyConfig[currency] ?? currencyConfig.VND;

  // Use dots for VND, commas for other currencies
  const numberFormat = currency === 'VND' ? 'de-DE' : locale;
  
  const formattedNumber = new Intl.NumberFormat(numberFormat, {
    maximumFractionDigits: config.decimals,
    minimumFractionDigits: config.decimals
  }).format(convertedAmount);

  if (config.symbolPosition === 'prefix') {
    return `${config.symbol}${formattedNumber}`;
  }

  return `${formattedNumber}${config.spaceBetween ? ' ' : ''}${config.symbol}`;
};

// Usage examples:
// formatCurrency(100000, rates, 'USD')  // "$100,000.00"
// formatCurrency(100000, rates, 'VND')  // "100,000₫"
// formatCurrency(100000, rates, 'EUR')  // "100,000.00 €"
