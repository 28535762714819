"use client"

import { UserData } from "@src/types/user"
import { useSession } from "next-auth/react"

const parseToArray = (str: string | string[] | undefined): string[] => {
  if (!str) return []
  
  if (typeof str === "string") {
    return str.split(",").filter(Boolean)
  }

  if (Array.isArray(str)) {
    return str.filter(Boolean)
  }

  return []
}

export const useCurrentUser = () => {
  const { data: session, status, update } = useSession()

  if (!session?.user) {
    return {
      user: null,
      status,
      update
    }
  }

  const userData: UserData = {
    ...session.user,
    product_ids: parseToArray(session.user.product_ids),
    collection_ids: parseToArray(session.user.collection_ids),
    event_ids: parseToArray(session.user.event_ids),
    blog_ids: parseToArray(session.user.blog_ids)
  }

  return {
    user: userData,
    status,
    update
  }
}
