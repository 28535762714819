"use client";

import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/system";
import { memo } from "react";
import { ButtonConfig, ENUM_BUTTON, LabelButton } from "./styled";
import { Icons } from "@src/components";
import { ButtonLinkProps, CancelButtonProps, ConfirmButtonProps, CustomButtonProps, DeleteButtonProps, EditButtonProps, NewButtonProps } from "@src/types/button-props";
import { Theme } from "@mui/material";
interface MuiButtonProps {
  theme?: Theme;
  buttontype?: string;
  borderRadius?: number;
  height?: number | string;
  minHeight?: number | string;
  heightMd?: number | string;
  minHeightMd?: number | string;
  minWidth?: number | string;
  minWidthMd?: number | string;
  sx?: React.CSSProperties;
}
const MuiButton = styled(LoadingButton, {
  shouldForwardProp: prop => !["buttontype", "borderRadius", "height", "minHeight", "heightMd", "minHeightMd", "minWidth", "minWidthMd"].includes(prop as string)
})<MuiButtonProps>(({
  theme,
  buttontype,
  borderRadius,
  height,
  minHeight,
  heightMd,
  minHeightMd,
  minWidth,
  minWidthMd,
  sx
}) => ({
  ...(ButtonConfig?.[buttontype || ""] || {}),
  display: "flex",
  justifyContent: "center",
  borderRadius: borderRadius || 16,
  height: height || minHeight || 48,
  minHeight: minHeight || 32,
  minWidth: minWidth || 64,
  outline: 0,
  "& .MuiLoadingButton-loadingIndicator": {
    position: "relative !important",
    marginRight: 8,
    left: 0
  },
  "&.MuiLoadingButton-loading": {
    color: "rgba(67, 67, 67, 0.6) !important"
  },
  "&.Mui-disabled": {
    opacity: 0.5
  },
  ...sx,
  [theme.breakpoints.down("md")]: {
    height: heightMd || minHeightMd || 40,
    minHeight: minHeightMd || minHeight || 40,
    minWidth: minWidthMd || minWidth || 64,
    "& p, span": {
      fontSize: "12px"
    }
  }
}));
const MuiLinkButton = styled(LoadingButton)({
  border: "none",
  boxShadow: "none",
  height: "fit-content",
  minHeight: "unset",
  minWidth: "unset",
  padding: "0px 4px",
  color: "#4150EA",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "transparent",
    border: "none"
  },
  " span": {
    color: "#4150EA",
    fontSize: "14px",
    lineHeight: "20px"
  },
  "&.Mui-disabled": {
    color: "rgba(67, 67, 67, 0.6) !important",
    backgroundColor: "transparent !important",
    border: "none",
    opacity: 0.5
  }
});
export const CancelButton = memo((props: CancelButtonProps) => {
  const {
    label = "Cancel",
    ...rest
  } = props;
  return <MuiButton buttontype={ENUM_BUTTON.CANCEL} variant="outlined" {...rest}>
      <LabelButton className="label">{label}</LabelButton>
    </MuiButton>;
});
export const ConfirmButton = memo((props: ConfirmButtonProps) => {
  const {
    label = "Save",
    ...rest
  } = props;
  return <MuiButton buttontype={ENUM_BUTTON.CONFIRM} variant="contained" {...rest}>
      <LabelButton className="label">{label}</LabelButton>
    </MuiButton>;
});
const DeleteButton = memo((props: DeleteButtonProps) => {
  const {
    label = "Delete",
    ...rest
  } = props;
  return <MuiButton buttontype={ENUM_BUTTON.DELETE} variant="contained" {...rest}>
      <LabelButton className="label">{label}</LabelButton>
    </MuiButton>;
});
const EditButton = memo((props: EditButtonProps) => {
  const {
    label = "Edit",
    ...rest
  } = props;
  return <MuiButton buttontype={ENUM_BUTTON.EDIT} variant="outlined" startIcon={<Icons name="edit-white" />} {...rest}>
      <LabelButton className="label">{label}</LabelButton>
    </MuiButton>;
});
const NewButton = memo((props: NewButtonProps) => {
  const {
    label = "New",
    ...rest
  } = props;
  return <MuiButton buttontype={ENUM_BUTTON.NEW} variant="outlined" startIcon={<Icons name="plus" />} {...rest}>
      <LabelButton className="label">{label}</LabelButton>
    </MuiButton>;
});
export const ButtonLink = memo((props: ButtonLinkProps) => {
  const {
    label = "Button",
    ...rest
  } = props;
  return <MuiLinkButton disableRipple variant="outlined" {...rest}>
      <LabelButton className="label">{label}</LabelButton>
    </MuiLinkButton>;
});
export const CustomButton = memo((props: CustomButtonProps) => {
  const {
    label = "Button",
    ...rest
  } = props;
  return <MuiButton buttontype={ENUM_BUTTON.CUSTOM} variant="outlined" {...rest}>
      <LabelButton className="label">{label}</LabelButton>
    </MuiButton>;
});