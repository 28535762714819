"use client";

import Image from "next/image";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
const NoImage = "/assets/images/no-image-placeholder.png";
const NextImage = props => {
  const {
    src,
    alt = "",
    sx,
    priority,
    width,
    height,
    sizes,
    loading,
    onLoad,
    ...rest
  } = props;
  const [imgSrc, setImgSrc] = useState(src);
  useEffect(() => {
    if (src) {
      setImgSrc(src);
    }
  }, [src]);
  const renderImage = () => {
    if (width && height) {
      return <Image width={width} height={height} priority={priority} loading={loading || "lazy"} src={imgSrc || NoImage} alt={alt || "image"} onError={event => setImgSrc(NoImage)} onLoad={onLoad} {...rest} />;
    } else {
      return <Image fill priority={priority} loading={loading || "lazy"} src={imgSrc} alt={alt || "image"} onLoad={onLoad} onError={event => setImgSrc(NoImage)} {...rest} />;
    }
  };
  return <Box sx={{
    position: "relative",
    width: "100%",
    height: "100%",
    "& img": {
      objectFit: "contain"
    },
    ...sx
  }} data-sentry-element="Box" data-sentry-component="NextImage" data-sentry-source-file="index.tsx">
      {renderImage()}
    </Box>;
};
export default NextImage;