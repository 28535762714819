import { RootState } from "../store"
import { ConfigType } from "../slices/configSlice"
import { createSelector } from "@reduxjs/toolkit"

// Base selector
export const selectConfig = (state: RootState): ConfigType | null =>
  state.config

// Cart
export const selectTotalCartItems = (state: RootState): number =>
  state.config?.total_cart_items ?? 0

// Keywords
export const selectTrendingKeywords = createSelector(
  state => state.config,
  config => config?.trending_keywords ?? []
)
export const selectCustomerKeywords = (state: RootState): string[] =>
  state.config?.customer_keywords ?? []

// Flash sale
export const selectFlashSale = (state: RootState) => state.config?.flash_sale
export const selectIsOnSale = (state: RootState): boolean =>
  state.config?.on_sale ?? false

// Collections and Categories
export const selectCollections = (state: RootState) =>
  state.config?.collections ?? []

export const selectCategories = (state: RootState) =>
  state.config?.categories ?? []

// Branches
export const selectBranches = (state: RootState) => state.config?.branches ?? []

// Payment
export const selectPaymentMethods = (state: RootState) =>
  state.config?.payment_methods ?? []

// SEO and Scripts
export const selectMetaTags = (state: RootState): string =>
  state.config?.meta ?? ""

export const selectMenuConfigList = createSelector(
  state => state.config,
  config => config?.menuListConfig ?? []
)

export const selectHeaderCode = (state: RootState): string =>
  state.config?.header_code ?? ""

export const selectFooterCode = (state: RootState): string =>
  state.config?.footer_code ?? ""

export const selectCurrencyRates = createSelector(state => state.config?.currencyRates, currencyRates => currencyRates ?? [])